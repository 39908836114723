import React from "react"
import { Link } from "gatsby"
import { BreadCrumb } from "../../StyleComponents/Layout"
import { isSSR } from "../Script/script"

const GoogleAds = React.lazy(() => import("../ADS/ADS"))

const NavBar = ({ listPages, removeADS=false }) => {
  const adsDivStyle = {
    minHeight: "100px",
    maxHeight: "100px",
    minWidth: "250px",
  }
  return (
    <>
      <div className="container">
        <nav aria-label="breadcrumb">
          <BreadCrumb>
            <li className="item">
              <Link to="/" activeClassName="item active">
                Home
              </Link>
            </li>
            {listPages
              ? listPages.map((page, index) => {
                  if (page.name) {
                    return (
                      <li key={index} className="item">
                        <Link activeClassName="item active" to={page.link}>
                          {page.name}
                        </Link>
                      </li>
                    )
                  } else return null
                })
              : ""}
          </BreadCrumb>
        </nav>
        {/* <!-- UnitPedia2021Hero --> */}
        {!removeADS &&
        <div className="mb-3" style={adsDivStyle}>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <GoogleAds
                insStyle={{
                  display: "inline-block",
                  minWidth: "250px",
                  maxWidth: "970px",
                  width: "100%",
                  height: "90px",
                }}
                slot="5749811086"
              />
            </React.Suspense>
          )}
        </div>}
      </div>
    </>
  )
}

export default React.memo(NavBar)