import React from "react"
import { Link } from "gatsby"

const SeeAlso = ({ title, seeAlsoList }) => {
  return (
    <>
      <p className="h3">{title ? title : "See Also"}</p>
      <ul>
        {seeAlsoList.map((e, index) => {
          let parts = e.split("/")
          return (
            <li key={index}>
              <Link to={`${e}/`}>
                {parts[parts.length - 1].split("-").join(" ")}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default React.memo(SeeAlso)
