import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Input,
  Field,
  Control,
  StaticButton,
  Columns,
  Column,
} from "../../../StyleComponents/styles"
import { FlexDiv, Table } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Converter", link: "/units-converter/" },
  {
    name: "Height Converter",
    link: "/height-unit-converter/",
  },
]
const seeAlsoArray = [
  "/length-units-converter",
  "/force-per-length-units-converter",
]

function HeightUnitsConverter(props) {
  let [Centimeters, setCentimeters] = useState("")
  let [Feet, setFeet] = useState("")
  let [Inches, setInches] = useState("")

  function CentimetersC(e) {
    let value = parseFloat(e.target.value)
    value = isNaN(value) ? "" : value

    setCentimeters(value)
    let realFeet = (value * 0.3937) / 12
    let feet = Math.floor(realFeet)
    setFeet(feet)
    let inch = Math.round((realFeet - Math.floor(realFeet)) * 12)
    setInches(inch)
  }
  function FeetC(e) {
    let value = parseFloat(e.target.value)
    value = isNaN(value) ? "" : value
    let result = value / 0.032808

    setCentimeters(Math.round(result))
    setFeet(value)
  }

  function InchesC(e) {
    let value = parseFloat(e.target.value)
    value = isNaN(value) ? "" : value

    var result = value / 0.3937
    let feeet = value / 0.032808
    setCentimeters(Math.round(result) + Math.round(feeet))
    setInches(value)
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Height Units Conversion - Simple Height Units Converter online"
        description="Height Units Conversion, Simple Measuring height Convert Centimeters to Feet + Inches Or Feet + Inches To Centimeters Units."
        keywords={[
          "Height units conversion, height converter,man height converter,Measuring height,Centimeters to Feet + Inches Or Feet, Feet and Inches to cm ,Feet + Inches To Centimeters,convert Inches and feet to cm, Centimeters,Feet ,Inches.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Measuring Height Units Converter</Title>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Centimeters</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  placeholder="centimeters"
                  value={Centimeters}
                  onInput={CentimetersC}
                  onChange={CentimetersC}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  Cm
                </StaticButton>
              </Control>
            </Field>
            <div style={{ fontSize: "1.5rem", textAlign: "center" }}>
              <strong>&#61;</strong>
            </div>
            <Field>
              <Control>
                <StaticButton>Feet</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  placeholder="feets"
                  value={Feet}
                  onInput={FeetC}
                  onChange={FeetC}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  Ft
                </StaticButton>
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>Inches</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  placeholder="inches"
                  value={Inches}
                  onInput={InchesC}
                  onChange={InchesC}
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  In
                </StaticButton>
              </Control>
            </Field>
          </Column>
          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <SmallImg filename="height.png" alt="height units conversion" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />
        <Table>
          <thead>
            <tr>
              <th>Cm</th>
              <th>Feet</th>
              <th>Feet+Inches</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>100cm</td>
              <td>3.28ft</td>
              <td>3ft + 3.37in</td>
            </tr>
            <tr>
              <td>101cm</td>
              <td>3.31ft</td>
              <td>3ft + 3.76in</td>
            </tr>
            <tr>
              <td>102cm</td>
              <td>3.35ft</td>
              <td>3ft + 4.16in</td>
            </tr>
            <tr>
              <td>103cm</td>
              <td>3.38ft</td>
              <td>3ft + 4.55in</td>
            </tr>
            <tr>
              <td>104cm</td>
              <td>3.41ft</td>
              <td>3ft + 4.94in</td>
            </tr>
            <tr>
              <td>105cm</td>
              <td>3.44ft</td>
              <td>3ft + 5.34in</td>
            </tr>
            <tr>
              <td>106cm</td>
              <td>3.48ft</td>
              <td>3ft + 5.73in</td>
            </tr>
            <tr>
              <td>107cm</td>
              <td>3.51ft</td>
              <td>3ft + 6.13in</td>
            </tr>
            <tr>
              <td>108cm</td>
              <td>3.54ft</td>
              <td>3ft + 6.52in</td>
            </tr>
            <tr>
              <td>109cm</td>
              <td>3.58ft</td>
              <td>3ft + 6.91in</td>
            </tr>
            <tr>
              <td>110cm</td>
              <td>3.61ft</td>
              <td>3ft + 7.31in</td>
            </tr>
            <tr>
              <td>111cm</td>
              <td>3.64ft</td>
              <td>3ft + 7.70in</td>
            </tr>
            <tr>
              <td>112cm</td>
              <td>3.67ft</td>
              <td>3ft + 8.09in</td>
            </tr>
            <tr>
              <td>113cm</td>
              <td>3.71ft</td>
              <td>3ft + 8.49in</td>
            </tr>
            <tr>
              <td>114cm</td>
              <td>3.74ft</td>
              <td>3ft + 8.88in</td>
            </tr>
            <tr>
              <td>115cm</td>
              <td>3.77ft</td>
              <td>3ft + 9.28in</td>
            </tr>
            <tr>
              <td>116cm</td>
              <td>3.81ft</td>
              <td>3ft + 9.67in</td>
            </tr>
            <tr>
              <td>117cm</td>
              <td>3.84ft</td>
              <td>3ft + 10.06in</td>
            </tr>
            <tr>
              <td>118cm</td>
              <td>3.87ft</td>
              <td>3ft + 10.46in</td>
            </tr>
            <tr>
              <td>119cm</td>
              <td>3.90ft</td>
              <td>3ft + 10.85in</td>
            </tr>
            <tr>
              <td>120cm</td>
              <td>3.94ft</td>
              <td>3ft + 11.24in</td>
            </tr>
            <tr>
              <td>121cm</td>
              <td>3.97ft</td>
              <td>3ft + 11.64in</td>
            </tr>
            <tr>
              <td>122cm</td>
              <td>4.00ft</td>
              <td>4ft + 0.03in</td>
            </tr>
            <tr>
              <td>123cm</td>
              <td>4.04ft</td>
              <td>4ft + 0.43in</td>
            </tr>
            <tr>
              <td>124cm</td>
              <td>4.07ft</td>
              <td>4ft + 0.82in</td>
            </tr>
            <tr>
              <td>125cm</td>
              <td>4.10ft</td>
              <td>4ft + 1.21in</td>
            </tr>
            <tr>
              <td>126cm</td>
              <td>4.13ft</td>
              <td>4ft + 1.61in</td>
            </tr>
            <tr>
              <td>127cm</td>
              <td>4.17ft</td>
              <td>4ft + 2.00in</td>
            </tr>
            <tr>
              <td>128cm</td>
              <td>4.20ft</td>
              <td>4ft + 2.39in</td>
            </tr>
            <tr>
              <td>129cm</td>
              <td>4.23ft</td>
              <td>4ft + 2.79in</td>
            </tr>
            <tr>
              <td>130cm</td>
              <td>4.27ft</td>
              <td>4ft + 3.18in</td>
            </tr>
            <tr>
              <td>131cm</td>
              <td>4.30ft</td>
              <td>4ft + 3.57in</td>
            </tr>
            <tr>
              <td>132cm</td>
              <td>4.33ft</td>
              <td>4ft + 3.97in</td>
            </tr>
            <tr>
              <td>133cm</td>
              <td>4.36ft</td>
              <td>4ft + 4.36in</td>
            </tr>
            <tr>
              <td>134cm</td>
              <td>4.40ft</td>
              <td>4ft + 4.76in</td>
            </tr>
            <tr>
              <td>135cm</td>
              <td>4.43ft</td>
              <td>4ft + 5.15in</td>
            </tr>
            <tr>
              <td>136cm</td>
              <td>4.46ft</td>
              <td>4ft + 5.54in</td>
            </tr>
            <tr>
              <td>137cm</td>
              <td>4.49ft</td>
              <td>4ft + 5.94in</td>
            </tr>
            <tr>
              <td>138cm</td>
              <td>4.53ft</td>
              <td>4ft + 6.33in</td>
            </tr>
            <tr>
              <td>139cm</td>
              <td>4.56ft</td>
              <td>4ft + 6.72in</td>
            </tr>
            <tr>
              <td>140cm</td>
              <td>4.59ft</td>
              <td>4ft + 7.12in</td>
            </tr>
            <tr>
              <td>141cm</td>
              <td>4.63ft</td>
              <td>4ft + 7.51in</td>
            </tr>
            <tr>
              <td>142cm</td>
              <td>4.66ft</td>
              <td>4ft + 7.91in</td>
            </tr>
            <tr>
              <td>143cm</td>
              <td>4.69ft</td>
              <td>4ft + 8.30in</td>
            </tr>
            <tr>
              <td>144cm</td>
              <td>4.72ft</td>
              <td>4ft + 8.69in</td>
            </tr>
            <tr>
              <td>145cm</td>
              <td>4.76ft</td>
              <td>4ft + 9.09in</td>
            </tr>
            <tr>
              <td>146cm</td>
              <td>4.79ft</td>
              <td>4ft + 9.48in</td>
            </tr>
            <tr>
              <td>147cm</td>
              <td>4.82ft</td>
              <td>4ft + 9.87in</td>
            </tr>
            <tr>
              <td>148cm</td>
              <td>4.86ft</td>
              <td>4ft + 10.27in</td>
            </tr>
            <tr>
              <td>149cm</td>
              <td>4.89ft</td>
              <td>4ft + 10.66in</td>
            </tr>
            <tr>
              <td>150cm</td>
              <td>4.92ft</td>
              <td>4ft + 11.06in</td>
            </tr>
            <tr>
              <td>151cm</td>
              <td>4.95ft</td>
              <td>4ft + 11.45in</td>
            </tr>
            <tr>
              <td>152cm</td>
              <td>4.99ft</td>
              <td>4ft + 11.84in</td>
            </tr>
            <tr>
              <td>153cm</td>
              <td>5.02ft</td>
              <td>5ft + 0.24in</td>
            </tr>
            <tr>
              <td>154cm</td>
              <td>5.05ft</td>
              <td>5ft + 0.63in</td>
            </tr>
            <tr>
              <td>155cm</td>
              <td>5.09ft</td>
              <td>5ft + 1.02in</td>
            </tr>
            <tr>
              <td>156cm</td>
              <td>5.12ft</td>
              <td>5ft + 1.42in</td>
            </tr>
            <tr>
              <td>157cm</td>
              <td>5.15ft</td>
              <td>5ft + 1.81in</td>
            </tr>
            <tr>
              <td>158cm</td>
              <td>5.18ft</td>
              <td>5ft + 2.20in</td>
            </tr>
            <tr>
              <td>159cm</td>
              <td>5.22ft</td>
              <td>5ft + 2.60in</td>
            </tr>
            <tr>
              <td>160cm</td>
              <td>5.25ft</td>
              <td>5ft + 2.99in</td>
            </tr>
            <tr>
              <td>161cm</td>
              <td>5.28ft</td>
              <td>5ft + 3.39in</td>
            </tr>
            <tr>
              <td>162cm</td>
              <td>5.31ft</td>
              <td>5ft + 3.78in</td>
            </tr>
            <tr>
              <td>163cm</td>
              <td>5.35ft</td>
              <td>5ft + 4.17in</td>
            </tr>
            <tr>
              <td>164cm</td>
              <td>5.38ft</td>
              <td>5ft + 4.57in</td>
            </tr>
            <tr>
              <td>165cm</td>
              <td>5.41ft</td>
              <td>5ft + 4.96in</td>
            </tr>
            <tr>
              <td>166cm</td>
              <td>5.45ft</td>
              <td>5ft + 5.35in</td>
            </tr>
            <tr>
              <td>167cm</td>
              <td>5.48ft</td>
              <td>5ft + 5.75in</td>
            </tr>
            <tr>
              <td>168cm</td>
              <td>5.51ft</td>
              <td>5ft + 6.14in</td>
            </tr>
            <tr>
              <td>169cm</td>
              <td>5.54ft</td>
              <td>5ft + 6.54in</td>
            </tr>
            <tr>
              <td>170cm</td>
              <td>5.58ft</td>
              <td>5ft + 6.93in</td>
            </tr>
            <tr>
              <td>171cm</td>
              <td>5.61ft</td>
              <td>5ft + 7.32in</td>
            </tr>
            <tr>
              <td>172cm</td>
              <td>5.64ft</td>
              <td>5ft + 7.72in</td>
            </tr>
            <tr>
              <td>173cm</td>
              <td>5.68ft</td>
              <td>5ft + 8.11in</td>
            </tr>
            <tr>
              <td>174cm</td>
              <td>5.71ft</td>
              <td>5ft + 8.50in</td>
            </tr>
            <tr>
              <td>175cm</td>
              <td>5.74ft</td>
              <td>5ft + 8.90in</td>
            </tr>
            <tr>
              <td>176cm</td>
              <td>5.77ft</td>
              <td>5ft + 9.29in</td>
            </tr>
            <tr>
              <td>177cm</td>
              <td>5.81ft</td>
              <td>5ft + 9.69in</td>
            </tr>
            <tr>
              <td>178cm</td>
              <td>5.84ft</td>
              <td>5ft + 10.08in</td>
            </tr>
            <tr>
              <td>179cm</td>
              <td>5.87ft</td>
              <td>5ft + 10.47in</td>
            </tr>
            <tr>
              <td>180cm</td>
              <td>5.91ft</td>
              <td>5ft + 10.87in</td>
            </tr>
            <tr>
              <td>181cm</td>
              <td>5.94ft</td>
              <td>5ft + 11.26in</td>
            </tr>
            <tr>
              <td>182cm</td>
              <td>5.97ft</td>
              <td>5ft + 11.65in</td>
            </tr>
            <tr>
              <td>183cm</td>
              <td>6.00ft</td>
              <td>6ft + 0.05in</td>
            </tr>
            <tr>
              <td>184cm</td>
              <td>6.04ft</td>
              <td>6ft + 0.44in</td>
            </tr>
            <tr>
              <td>185cm</td>
              <td>6.07ft</td>
              <td>6ft + 0.83in</td>
            </tr>
            <tr>
              <td>186cm</td>
              <td>6.10ft</td>
              <td>6ft + 1.23in</td>
            </tr>
            <tr>
              <td>187cm</td>
              <td>6.14ft</td>
              <td>6ft + 1.62in</td>
            </tr>
            <tr>
              <td>188cm</td>
              <td>6.17ft</td>
              <td>6ft + 2.02in</td>
            </tr>
            <tr>
              <td>189cm</td>
              <td>6.20ft</td>
              <td>6ft + 2.41in</td>
            </tr>
            <tr>
              <td>190cm</td>
              <td>6.23ft</td>
              <td>6ft + 2.80in</td>
            </tr>
            <tr>
              <td>191cm</td>
              <td>6.27ft</td>
              <td>6ft + 3.20in</td>
            </tr>
            <tr>
              <td>192cm</td>
              <td>6.30ft</td>
              <td>6ft + 3.59in</td>
            </tr>
            <tr>
              <td>193cm</td>
              <td>6.33ft</td>
              <td>6ft + 3.98in</td>
            </tr>
            <tr>
              <td>194cm</td>
              <td>6.36ft</td>
              <td>6ft + 4.38in</td>
            </tr>
            <tr>
              <td>195cm</td>
              <td>6.40ft</td>
              <td>6ft + 4.77in</td>
            </tr>
            <tr>
              <td>196cm</td>
              <td>6.43ft</td>
              <td>6ft + 5.17in</td>
            </tr>
            <tr>
              <td>197cm</td>
              <td>6.46ft</td>
              <td>6ft + 5.56in</td>
            </tr>
            <tr>
              <td>198cm</td>
              <td>6.50ft</td>
              <td>6ft + 5.95in</td>
            </tr>
            <tr>
              <td>199cm</td>
              <td>6.53ft</td>
              <td>6ft + 6.35in</td>
            </tr>
            <tr>
              <td>200cm</td>
              <td>6.56ft</td>
              <td>6ft + 6.74in</td>
            </tr>
          </tbody>
        </Table>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default HeightUnitsConverter
